import {
  GetBanner2Document,
  GetBanner2Query,
  GetBanner2QueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getBanner2(stage: string, id: string, locale: string) {
  return client.query<GetBanner2Query, GetBanner2QueryVariables>({
    query: GetBanner2Document,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
