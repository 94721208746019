import {
  GetCorpMediaDownloadPreviewDocument,
  GetCorpMediaDownloadPreviewQuery,
  GetCorpMediaDownloadPreviewQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpMediaDownloadPreview(stage: string, id: string, locale: string) {
  return client.query<GetCorpMediaDownloadPreviewQuery, GetCorpMediaDownloadPreviewQueryVariables>({
    query: GetCorpMediaDownloadPreviewDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
