import {
  GetCorpSocialMediaFooterDocument,
  GetCorpSocialMediaFooterQuery,
  GetCorpSocialMediaFooterQueryResult,
  GetCorpSocialMediaFooterQueryVariables,
  Locale,
  Markets,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpSocialMediaFooter(stage: string, locale: string) {
  return client.query<GetCorpSocialMediaFooterQuery, GetCorpSocialMediaFooterQueryVariables>({
    query: GetCorpSocialMediaFooterDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
    },
  });
}
