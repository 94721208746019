import {
  GetCorpVideoHeroComponentDocument,
  GetCorpVideoHeroComponentQuery,
  GetCorpVideoHeroComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpVideoHeroComponent(stage: string, id: string, locale: string) {
  return client.query<GetCorpVideoHeroComponentQuery, GetCorpVideoHeroComponentQueryVariables>({
    query: GetCorpVideoHeroComponentDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
