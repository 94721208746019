import {
  GetCorpNavigationDocument,
  GetCorpNavigationQuery,
  GetCorpNavigationQueryVariables,
  Locale,
  Markets,
  Stage,
} from 'generated/graphql';
import {client} from './client';
import {CorpNavDataType, corpNavigation} from "../../types/CorpNavData";
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpNavigation(market: string, stage: string, locale: string):
  Promise<{data: CorpNavDataType}> {

  const initialData = await client.query<GetCorpNavigationQuery, GetCorpNavigationQueryVariables>({
    query: GetCorpNavigationDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
    },
  });
  let corpNavigations: Array<corpNavigation> = [];

  if (initialData.data.corpNavigations && initialData.data.corpNavigations.length > 0) {
    corpNavigations = initialData.data.corpNavigations.map((corpNavigation) => {
      return {
        id: corpNavigation.id,
        linkText: corpNavigation.linkText,
        navigationItems: corpNavigation.navigationItems.filter((navigationItem) => {
          return (!navigationItem.excludedMarkets.includes(market as Markets) && (
            navigationItem.markets.includes(market as Markets) || navigationItem.markets.length === 0
          ))
        })
      }
    });
  }

  return {
    data: {
      corpNavigations: Object.values(corpNavigations)
    }
  };
}
