import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Grid,
  GridRow,
  GridItem,
} from '../components/Grid';
import { IntroText } from '../components/IntroText';
import { GetStaticProps } from 'next';
import { Error404Icon } from '../components/Icons/icons';
import { CorporateButton } from '../components/CorporateButton';
import { useRouter } from 'next/router';
import { contentGroups } from '../lib/gtm';
import { getDictionary } from '../lib/graphql/getDictionary';
import { getLocale } from '../modules/localesMapper';
import { getCMSStage } from '../lib/utils/helpers';
import { getCopy } from '../lib/utils/getCopy';
import { NextSeo } from 'next-seo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  text-align: center;
  padding: 80px 0;
`;

const Custom404 = ({ dictionary }: { dictionary: any }) => {
  const { locale } = useRouter();
  useEffect(() => {
    contentGroups({ cg1: 'error page' });
  }, []);
  const title = getCopy('404_title', dictionary);
  const description = getCopy('404_description', dictionary);
  return (
    <>
      <NextSeo
        title={title}
        description={description}
      />
      <Container className="flex flex-col justify-center w-full text-center min-h-screen -mt-10 -mb-10 md:mt-0 md:mb-0">
        <Grid>
          <GridRow columns={16}>
            <GridItem colStart={6} colSpan={6}>
              <div className="justify-center"><Error404Icon className="w-full" /></div>
            </GridItem>
            <GridItem colStart={1} colSpan={16}>
              <div className="text-center flex flex-col items-center justify center">
                <IntroText
                  superscript=""
                  title={title}
                  description={description}
                  titleTag=""
                />
                <a href={`/${locale === 'global' ? '' : locale}`} >
                  <CorporateButton
                    label={getCopy('404_button', dictionary)}
                    type="button"
                    buttonType='button'
                    color="primary"
                  />
                </a></div>
            </GridItem>
          </GridRow>
        </Grid>

      </Container>
    </>
  );
};

Custom404.defaultProps = {
  errorData: {
    title: 'Ops',
  },
};

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ locale = 'en_GB' }) => {
  // const stage = process.env.CMS_STAGE ? process.env.CMS_STAGE : 'PUBLISHED';
  //let errorData: any = await getErrorPage(locale, stage, 'error_404');
  // use en_GB as fallback so build completes
  // if (errorData?.data?.errorPages?.length === 0) {
  //   //errorData = await getErrorPage('en_GB', stage, 'error_404');
  // }

  // const market = locale; // locale from path is representing market
  // const currLocale = getLocale(market);
  // const stage = getCMSStage();
  // const dictionary = await getDictionary(currLocale, stage, 'General');
  return {
    props: {
      locale,
      env: process.env.RECIPE_ENV?.toLowerCase() || 'dev',
      errorData: null,
    },
  };
};