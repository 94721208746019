import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, Title } from './../Typography';
import React, { useEffect, useRef, useState } from 'react';
import { DictionaryItem } from 'types/Dictionary';
import { CorpNavigationItem, Markets } from '../../generated/graphql';
import { CameoLogoIcon, LeftArrowIcon, OetkerLogoIcon, SearchIcon, CloseIcon } from '../Icons/icons';
import {
  MenuButton,
  NavBar,
  NavFirstLevel,
  NavigationContainer,
  NavItem,
  NavItemBack,
  NavLogo,
  NavSecondLevel,
  SearchButton,
} from './styles';
import { getCopy } from '../../lib/utils/getCopy';
import { corpNavigation } from "../../types/CorpNavData";
import { CorpLink } from '../CorpLink';
import { CorpSearchOverlay } from '../CorpSearchOverlay';

export function CorpNavigation(props: {
  corpNavData: corpNavigation[];
  market: Markets;
  locale: string;
  copy: DictionaryItem[];
}) {
  const { corpNavData, market, copy, locale } = props;
  const [firstLevelOpen, setFirstLevelOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [secondLevelItems, setSecondLevelItems] = useState<Partial<CorpNavigationItem>[]>([]);
  const refFirst = useRef<HTMLUListElement>(null);
  const refSecond = useRef<HTMLUListElement>(null);
  const refMenuButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      // close menu if open and if not clicked on menu button, first or secondlevel menu
      if (firstLevelOpen &&
        !refFirst.current?.contains(event.target) &&
        !refSecond.current?.contains(event.target) &&
        !refMenuButton.current?.contains(event.target)) {
        toggleMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [firstLevelOpen, secondLevelItems]);

  const toggleMenu = () => {
    if (firstLevelOpen || secondLevelItems?.length > 0) {
      setFirstLevelOpen(false);
      setSecondLevelItems([]);
      document.body.style.overflow = '';
    } else {
      setFirstLevelOpen(true);
      // prevent scrolling of page
      document.body.style.overflow = 'hidden';
    }
  };

  function SecondLevelItem({ secondLevelItem }: any) {
    // filter out links that are not meant to be displayed here
    if (secondLevelItem.markets?.length > 0 && secondLevelItem.markets.indexOf(market) === -1) {
      return <></>;
    }
    const classes = ['pl-10 md:pl-20'];
    secondLevelItem.highlighted ? classes.push('py-8 md:py-12 corp-nav-item-highlighted') : classes.push('py-4 corp-nav-item');
    return (
      <NavItem
        $highlighted={!!secondLevelItem.highlighted}
        $secondLevelItem
        className={classes.join(' ')}
      >
        <CorpLink link={secondLevelItem?.link} className="flex items-center" onClick={toggleMenu}>
          <Title type={secondLevelItem.highlighted ? 'xs' : 'xxs'} tag="button" className="text-left">
            {secondLevelItem.link?.displayName}
          </Title>
          {secondLevelItem.highlighted && (
            <FontAwesomeIcon icon={faChevronRight} size="1x" className="ml-4" />
          )}
        </CorpLink>
      </NavItem>
    );
  }

  const url = market === Markets.global || market === Markets.it ? '/' : `/${market}`

  const onSearch = async () => {
    setSearchOpen((open) => !open);
  }
  return (
    <NavigationContainer>
      <NavBar className="h-14 flex justify-between bg-white">
        <MenuButton ref={refMenuButton} onClick={toggleMenu}>
          <div className={firstLevelOpen ? 'menu open' : 'menu'}>
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <Text type="lead" tag="p" className="hidden md:block">
            {getCopy('menu', copy)}
          </Text>
        </MenuButton>
        <NavLogo href={url}>
          {market === Markets.it ? <CameoLogoIcon /> : <OetkerLogoIcon />}
          <span className='text-darkgray'>{getCopy('the_company', copy)}</span>
        </NavLogo>
        <SearchButton className={`items-end flex flex-col ${searchOpen ? 'p-4' : 'p-5'}`} onClick={onSearch}>{searchOpen ? <CloseIcon className="h-full" /> : <SearchIcon className="h-full" />}</SearchButton>
      </NavBar>
      <NavFirstLevel ref={refFirst} className="pl-10 pt-16 md:border-r-3" $isOpen={firstLevelOpen}>
        {corpNavData.map((firstLevelItem: any) => (
          <NavItem
            key={firstLevelItem.id}
            $secondLevelItem={false}
            onClick={() => setSecondLevelItems(firstLevelItem.navigationItems)}
            className="pl-10 md:pl-20 mt-8 first:mt-12"
          >
            <Title type="xs" tag="button">
              {firstLevelItem.linkText}
            </Title>
          </NavItem>
        ))}
      </NavFirstLevel>
      <NavSecondLevel ref={refSecond} className="md:border-r-3 pb-40 lg:pb-0" $isOpen={secondLevelItems.length > 0}>
        <NavItemBack onClick={() => setSecondLevelItems([])}>
          <Text type="lead" tag="button" className="flex items-center">
            <LeftArrowIcon className="full-height w-3" />
            <span className="ml-2">{getCopy('back', copy)}</span>
          </Text>
        </NavItemBack>
        {secondLevelItems?.length > 0 &&
          secondLevelItems.map((secondLevelItem) => (
            <SecondLevelItem key={secondLevelItem.id} secondLevelItem={secondLevelItem} />
          ))}
      </NavSecondLevel>
      {searchOpen && <CorpSearchOverlay copy={copy} locale={locale} market={market} setOpenSearch={open => setSearchOpen(open)} />}
    </NavigationContainer>
  );
}
