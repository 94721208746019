import { client } from 'lib/graphql/client';
import {
  GetCountrySelectorDocument,
  GetCountrySelectorQuery,
  GetCountrySelectorQueryVariables,
  Locale,
  Stage,
} from 'generated/graphql';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCountrySelectorCMSData(stage: string, locale: string) {
  return client.query<GetCountrySelectorQuery, GetCountrySelectorQueryVariables>({
    query: GetCountrySelectorDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
    },
  });
}
