import {
  GetCorpContactCardContainerDocument,
  GetCorpContactCardContainerQuery,
  GetCorpContactCardContainerQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpContactCardContainer(stage: string, id: string, locale: string) {
  return client.query<GetCorpContactCardContainerQuery, GetCorpContactCardContainerQueryVariables>({
    query: GetCorpContactCardContainerDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
