import {
  GetLinkDocument,
  GetLinkQuery,
  GetLinkQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getLink(stage: string, id: string, locale: string) {
  return client.query<GetLinkQuery, GetLinkQueryVariables>({
    query: GetLinkDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
