import {
  GetExampleComponentDocument,
  GetExampleComponentQuery,
  GetExampleComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getExampleComponent(stage: string, id: string, locale: string) {
  return client.query<GetExampleComponentQuery, GetExampleComponentQueryVariables>({
    query: GetExampleComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
