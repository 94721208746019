import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import { log } from '../utils/logger';

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, originalError }) =>
      log.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }
  if (networkError) {
    log.error(`[Network error]: ${networkError.message}`);
  }
});

// uncomment to debug graphql queries
// async function loggingFetch (input: RequestInfo, init?: RequestInit): Promise<Response> {
//   const body = JSON.parse(init?.body ?? '{}')

//   const start = Date.now()
//   //console.log(`${new Date().toISOString().slice(-13)} 📡 Sending ${body.operationName} request`)
//   const response = await fetch(input, init)
//   console.log(`${new Date().toISOString().slice(-13)} 📡 Received ${body.operationName} response in ${Date.now() - start}ms`)

//   return {
//     ...response,

//     async text () {
//       const start = Date.now()
//       const result = await response.text()
//       //console.log(`${new Date().toISOString().slice(-13)} ⚙️  Read ${body.operationName} response body in ${Date.now() - start}ms (${result.length} bytes)`)
//       return result
//     }
//   }
// }
const { publicRuntimeConfig } = getConfig();
console.info(`Using graphql endpoint: ${publicRuntimeConfig?.graphqlEndpoint}`);
const httpLink = new HttpLink({
  uri: publicRuntimeConfig?.graphqlEndpoint,
  //fetch: loggingFetch
});

const hygraphCacheHeadersMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'hyg-stale-if-error': process.env.HYGRAPH_STALE_IF_ERROR_DURATION,
      'hyg-stale-while-revalidate': process.env.HYGRAPH_STALE_WHILE_REVALIDATE_DURATION,
    },
  }));

  return forward(operation);
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
export const client = new ApolloClient({
  uri: publicRuntimeConfig?.graphqlEndpoint,
  cache: new InMemoryCache(),
  defaultOptions,
  link: from([hygraphCacheHeadersMiddleware, errorLink, httpLink]),
});
