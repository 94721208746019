import {
  GetLocationComponentDocument,
  GetLocationComponentQuery,
  GetLocationComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import {getGenericPageComponents} from "./getGenericPageComponents";
import {ITabs} from "../../components/Tabs/types";
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getLocationComponent(stage: string, id: string, locale: string, market: string | null): Promise<any> {
  let data: any;
  try {
    data = await client.query<GetLocationComponentQuery, GetLocationComponentQueryVariables>({
      query: GetLocationComponentDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        stage: stage as Stage,
        where: {
          id: id as Scalars['ID'],
        },
      },
    });
    // console.log('data', data.data?.locationComponent?.locationItems)
    if( data.data?.locationComponent?.locationItems &&
        data.data?.locationComponent?.locationItems.length > 0) {
      const promiseCollector = data.data.locationComponent.locationItems.map(async (item: any) => {
        if (item.bodyComponents && item.bodyComponents.length > 0) {
          const bodyComponents = await getGenericPageComponents(
              stage,
              item.bodyComponents,
              locale,
              market
          );
          item.bodyComponents = bodyComponents;
        }
        return item;
      });
      data.data.locationComponent.locationItems = await Promise.all(promiseCollector);
    }
    return data;
  } catch (e) {
    throw new Error(`getLocationComponent error ${e}`);
  }
}
