import { ApolloProvider } from '@apollo/client';
import { config } from '@fortawesome/fontawesome-svg-core';
import { client } from 'lib/graphql/client';
import { pageview } from 'lib/gtm';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// styles
import '../styles/fonts.css';
import '../styles/global.css';
import '../styles/hero-mask.css';

// third party css
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { TWContainer } from '../components/TWContainer';
import { CorpFooter } from '../components/CorpFooter';
import { UpArrow } from '../components/UpArrow';
import { CorpAnchorNavigation } from 'components/CorpAnchorNavigation';
import { getHeaderNavCMSData, getMobileOnlyCMSData } from '../lib/graphql/getHeaderNav';
import { getFooterNavigation } from '../lib/graphql/getFooterNav';
import { getCorpNavigation } from '../lib/graphql/getCorpNavigation';
import { getLocale } from '../modules/localesMapper';
import { getCountrySelectorCMSData } from '../lib/graphql/getCountrySelector';
import { CorpNavigation } from '../components/CorpNavigation';
import { FooterNav } from '../types/footerNav';
import { Markets } from '../generated/graphql';
import CountrySelector from '../types/countrySelectorData';
import { getDictionary } from '../lib/graphql/getDictionary';
import { DictionaryItem } from '../types/Dictionary';
import Script from 'next/script';
import { corpNavigation } from '../types/CorpNavData';
import { getCopy } from '../lib/utils/getCopy';
import { PageMetaData } from '../components/metaData';
import Head from 'next/head';
import { getCMSStage } from '../lib/utils/helpers';
import { getCorpNotificationBanner } from '../lib/graphql/getCorpNotificationBanner';
import { CorpNotificationBanner } from '../components/CorpNotificationBanner';
import { ICorpNotificationBannerProps } from '../components/CorpNotificationBanner/types';
import { findInternalLink } from '../lib/graphql/getGenericPageComponents';
import { getCorpSocialMediaFooter } from '../lib/graphql/getCorpSocialMediaFooter';
import { ICorpSocialMediaFooter } from '../types/ICorpSocialMediaFooter';
import getConfig from 'next/config';

config.autoAddCss = false;

interface AppPropsExtended extends AppProps {
  domain?: string;
  locales?: string[];
  locale: string;
  defaultLocale?: string;
  globalMetadata?: {
    siteName?: string;
    url?: string;
    titleTemplate?: string;
    description?: string;
    facebookAppId?: any;
    image?: {
      url?: string;
      height?: number;
      width?: number;
      __typename?: string;
    };
    twitterSeo?: {
      handle?: any;
      site?: string;
      cardType?: string;
      __typename?: string;
    };
    __typename?: string;
  };
  notificationBanner: ICorpNotificationBannerProps;
  socialMediaFooter: ICorpSocialMediaFooter;
  footerNavData: FooterNav;
  corpNavData: corpNavigation[];
  countrySelectorData: CountrySelector[];
  market: Markets;
  dictionary: DictionaryItem[];
}

function CustomApp({
  countrySelectorData,
  footerNavData,
  market,
  corpNavData,
  Component,
  pageProps,
  domain,
  globalMetadata,
  locales,
  defaultLocale,
  notificationBanner,
  socialMediaFooter,
  dictionary,
  locale,
}: AppPropsExtended) {
  const { publicRuntimeConfig } = getConfig();
  const { cameoDomain, oetkerDomain } = publicRuntimeConfig;

  const getSupportedLanguages = () =>
    locales?.map((item: string) => ({
      hrefLang: item.replace('_', '-'),
      href: `${domain}/${item}`,
    }));
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  const links = pageProps?.anchorNavigationList?.anchorNavigations?.map((nav: any) => ({
    id: nav.id,
    url: nav.navigationTitle.split(' ').join('-'),
    displayName: nav.navigationTitle,
  }));

  const footerCountrySelectorData = countrySelectorData?.[0] || undefined;
  // Done in this fashion to bring in the correct data for the pageProps
  // Probably could be done better in the future
  // eslint-disable-next-line no-param-reassign
  pageProps = { ...pageProps, ...pageProps?.data?.genericPages?.[0], dictionary: dictionary };

  // iterate over footer links to get the correct links
  const footerLinks = footerNavData.footerNavigations.map((footerRow): any => {
    return {
      ...footerRow,
      links:
        footerRow && footerRow?.links && footerRow?.links.length > 0
          ? footerRow.links.map((footerLink): any => {
              // external link to another page
              if (footerLink?.url?.indexOf('http') > -1) {
                return footerLink;
              }

              // internal link
              if (footerLink?.internalLink && footerLink?.internalLink?.slug) {
                const prefix = router.locale === 'global' ? '/' : `/${router.locale}/`;

                return {
                  ...footerLink,
                  url: `${prefix}${footerLink?.internalLink?.slug}`,
                };
              }

              // external link to a CMS page, for example to /media-library
              const prefix = router.locale === 'global' ? '' : `/${router.locale}`; // trailing slash missing here
              return {
                ...footerLink,
                url: `${prefix}${footerLink?.url || ''}`,
              };
            })
          : [],
    };
  });
  const faviconUrl = locale === 'it' ? '/images/cameo.ico' : '/images/droetker.ico';

  // we have to switch GTM based on defaultLocale (check domain routing in next.config.js )
  const isItalianPage = defaultLocale === 'it';
  const tagManagerID = isItalianPage
    ? process.env.NEXT_PUBLIC_GTM_CAMEO
    : process.env.NEXT_PUBLIC_GTM_GLOBAL;

  const onMarketChange = async (newMarket: string) => {
    const lowerCaseMarket = newMarket.toLowerCase();
    let href: string;
    if (typeof window !== undefined) {
      if (lowerCaseMarket !== market) {
        if (lowerCaseMarket !== 'it') {
          // set cookie
          await fetch(`/api/market?market=${lowerCaseMarket}`);
          // switch to other market page
          href = `//${oetkerDomain}${lowerCaseMarket === 'global' ? '' : `/${lowerCaseMarket}`}`;
        } else {
          // switch to italian page
          href = `//${cameoDomain}`;
        }
        // wait for cookie to be set
        setTimeout(() => {
          window.location.href = href;
        }, 500);
      }
    }
  };

  const hideNavigation = pageProps?.data?.genericPages?.at(0)?.hideNavigation;

  return (
    <ApolloProvider client={client}>
      <Head>
        <link rel="shortcut icon" href={faviconUrl} />
        <meta
          content={`DEBUG: ${cameoDomain} || ${oetkerDomain} || ${process.env.NEXT_PUBLIC_GRAPHCMS_ENDPOINT}`}
        />
      </Head>
      <TWContainer>
        {pageProps && pageProps.globalMetadata && (
          <DefaultSeo
            languageAlternates={getSupportedLanguages()}
            titleTemplate={`${pageProps?.globalMetadata?.titleTemplate} | %s`}
            {...(pageProps.globalMetadata?.facebookAppId && {
              facebook: { appId: pageProps?.globalMetadata?.facebookAppId },
            })}
            openGraph={{
              type: 'website',
              url: pageProps?.globalMetadata?.url,
              site_name: pageProps?.globalMetadata?.siteName,
              description: pageProps?.globalMetadata?.description,
              ...(pageProps?.globalMetadata?.image && {
                images: [pageProps?.globalMetadata?.image],
              }),
              locale: pageProps?.locale,
            }}
            {...(pageProps?.globalMetadata?.twitterSeo && {
              twitter: {
                cardType: pageProps?.globalMetadata?.twitterSeo.cardType,
                site: pageProps?.globalMetadata?.twitterSeo.site,
              },
            })}
          />
        )}
        {pageProps && pageProps.metaData && (
          <PageMetaData metadata={pageProps?.metaData} locale={locale} />
        )}
        <div>
          {!hideNavigation && (
            <>
              <div className="h-14" />
              <CorpNavigation
                corpNavData={corpNavData}
                market={market}
                copy={dictionary || null}
                locale={locale}
              />
            </>
          )}
          {links?.length > 0 && <CorpAnchorNavigation links={links} />}
          {notificationBanner && <CorpNotificationBanner {...notificationBanner} />}

          <Component {...pageProps} globalMetadata={globalMetadata} />
          {footerNavData && (
            <CorpFooter
              onMarketChange={onMarketChange}
              socialMediaFooter={socialMediaFooter}
              footerData={{
                ...footerNavData,
                footerNavigations: footerLinks,
                privacySettingsTitle: getCopy('privacy_settings', dictionary),
              }}
              countrySelectorData={footerCountrySelectorData}
              currentMarket={market}
              theme="Corporate"
            />
          )}
          <div className="absolute z-50">
            <UpArrow isCorporate />
          </div>
        </div>
      </TWContainer>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${tagManagerID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <Script
        data-usercentrics="Google Analytics"
        id="ga-ua"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
            {'gtm.start': new Date().getTime(),event:'gtm.js'}
            );var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${tagManagerID}');
          `,
        }}
      ></Script>
      <Script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-settings-id="nZOAffem9"
        strategy="afterInteractive"
      />
    </ApolloProvider>
  );
}

CustomApp.defaultProps = {
  domain: '',
  globalMetadata: {},
  locales: [],
  locale: 'default',
};

CustomApp.getInitialProps = async ({ ctx }: { ctx: any }) => {
  const market = ctx.locale || 'global';
  // locale from path is representing market
  const currLocale = getLocale(market);
  const stage = getCMSStage();
  const getHeaderNavData = await getHeaderNavCMSData(stage, currLocale);
  const footerNavData = await getFooterNavigation(stage, currLocale);
  const getMobileNavData = await getMobileOnlyCMSData(stage);
  const corpNavData = await getCorpNavigation(market, stage, currLocale);
  const countrySelectorData = await getCountrySelectorCMSData(stage, currLocale);
  const dictionary = await getDictionary(currLocale, stage, 'General');

  const socialMediaFooters = await getCorpSocialMediaFooter(stage, currLocale);
  const socialMediaFooter =
    socialMediaFooters?.data?.corpSocialMediaFooters?.length > 0
      ? socialMediaFooters.data.corpSocialMediaFooters[0]
      : null;
  const notificationBanners = await getCorpNotificationBanner(stage, currLocale, market);
  const notificationBanner =
    notificationBanners?.data?.corpNotificationBanners?.length > 0
      ? notificationBanners.data.corpNotificationBanners[0]
      : null;
  findInternalLink(notificationBanner, currLocale);

  // @TODO: enable once fixed
  // const globalMetadata = await getGlobalMetaData(stage, currLocale);
  const dictionaryItems =
    dictionary?.data?.dictionary?.dictionaryItems?.length > 0
      ? dictionary.data.dictionary.dictionaryItems
      : [];
  return {
    getHeaderNav: getHeaderNavData.data.navigations,
    footerNavData: footerNavData.data,
    getMobileNavData: getMobileNavData.data.mobileOnlyMainNavigation,
    countrySelectorData: countrySelectorData.data.countrySelectors,
    corpNavData: corpNavData.data.corpNavigations,
    dictionary: dictionaryItems,
    market,
    notificationBanner,
    socialMediaFooter,
    locale: currLocale,
    defaultLocale: ctx?.defaultLocale,
  };
};

export default CustomApp;
