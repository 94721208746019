import {
  GetCorpNotificationBannerDocument,
  GetCorpNotificationBannerQuery,
  GetCorpNotificationBannerQueryVariables,
  Locale,
  Markets,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpNotificationBanner(stage: string, locale: string, market: Markets) {
  return client.query<GetCorpNotificationBannerQuery, GetCorpNotificationBannerQueryVariables>({
    query: GetCorpNotificationBannerDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
      where: {
        market
      },
    },
  });
}
