import { client } from 'lib/graphql/client';
// import { getMarket } from 'modules/localesMapper';
import {
  GetHeaderDocument,
  GetHeaderQuery,
  GetHeaderQueryVariables,
  GetMobileHeaderDocument,
  GetMobileHeaderQuery,
  GetMobileHeaderQueryVariables,
  Locale,
  Stage,
} from 'generated/graphql';

import { getLocalesForGraphQL } from '../utils/helpers';

export async function getHeaderNavCMSData(stage: string, locale: string) {
  // const market = getMarket(locale);
  return client.query<GetHeaderQuery, GetHeaderQueryVariables>({
    query: GetHeaderDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        // market: market as Markets,
      },
    },
  });
}

export async function getMobileOnlyCMSData(
  // locale: string,
  stage: string,
) {
  // const market = getMarket(locale);
  return client.query<GetMobileHeaderQuery, GetMobileHeaderQueryVariables>({
    query: GetMobileHeaderDocument,
    variables: {
      stage: stage as Stage,
      where: {
        key: 'PrimaryNavAsset',
        // market: market as Markets,
      },
    },
  });
}
