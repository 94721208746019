import {
  GetIntroTextDocument,
  GetIntroTextQuery,
  GetIntroTextQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getIntroText(stage: string, id: string, locale: string) {
  return client.query<GetIntroTextQuery, GetIntroTextQueryVariables>({
    query: GetIntroTextDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
