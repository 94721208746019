// TODO 2199 delete this file when QuickLink no longer in use in cms
import {
  GetQuickLinkDocument,
  GetQuickLinkQuery,
  GetQuickLinkQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getQuickLink(stage: string, id: string, locale: string) {
  return client.query<GetQuickLinkQuery, GetQuickLinkQueryVariables>({
    query: GetQuickLinkDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
