import {
  GetShortHeroComponentDocument,
  GetShortHeroComponentQuery,
  GetShortHeroComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getShortHeroComponent(stage: string, id: string, locale: string) {
  return client.query<GetShortHeroComponentQuery, GetShortHeroComponentQueryVariables>({
    query: GetShortHeroComponentDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
