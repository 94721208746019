import {
  GetHeroComponentDocument,
  GetHeroComponentQuery,
  GetHeroComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getHeroComponent(stage: string, id: string, locale: string) {
  return client.query<GetHeroComponentQuery, GetHeroComponentQueryVariables>({
    query: GetHeroComponentDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
