import React from 'react';
import { ButtonText, IconWrapper, StyledButton } from './styles';

export interface ICorporateButtonProps {
  id?: string;
  label?: string;
  size?: 'default' | 'small';
  color?: 'primary' | 'primaryalternative' | 'secondary' | 'tertiary';
  className?: string;
  type?: any;
  icon?: JSX.Element;
  buttonType?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonColors = {
  primary:
    'bg-corporate-offWhite border-2 hover:bg-corporate-superOffWhite active:border-corporate-blue active:bg-corporate-offWhite px-2 py-1 border-transparent',
  primaryalternative:
    'bg-corporate-superOffWhite border-2  hover:bg-corporate-offWhite active:border-corporate-blue  active:bg-corporate-superOffWhite px-2 py-1 border-transparent',
  secondary:
    'bg-corporate-offWhite border hover:bg-corporate-superOffWhite border border-corporate-darkGray active:border-2   px-2 py-1 active:bg-corporate-offWhite',
  tertiary:
    'border-1 text-corporate-darkGray border-corporate-darkGray hover:bg-corporate-superOffWhite active:bg-corporate-offWhite  border border-corporate-darkGray border active:border-2   px-0 py-0',
};
const ButtonTextColors = {
  primary: 'border-corporate-red border px-10 text-sm h-10',
  primaryalternative: 'border-corporate-red border px-10 text-sm h-10',
  secondary: 'px-10 text-sm h-13',
  tertiary: 'px-5 text-sm h-10',
};
export const CorporateButton = ({
  id = 'corporate-button',
  label,
  color = 'primary',
  buttonType = 'button',
  type,
  disabled = false,
  className,
  onClick,
  onMouseEnter,
  icon,
  onMouseLeave,
}: ICorporateButtonProps) => {
  return (
    <StyledButton
      id={id}
      data-test={id}
      className={className}
      $color={ButtonColors[color]}
      disabled={disabled}
      type={buttonType}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonText type={type} tag="span" $color={ButtonTextColors[color]}>
        {icon && (
          <IconWrapper $disabled={disabled} $hasLabel={!!(label && label.length > 0)}>
            {icon}
          </IconWrapper>
        )}
        {label}
      </ButtonText>
    </StyledButton>
  );
};
