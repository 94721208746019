import {
  GetTabsDocument,
  GetTabsQuery,
  GetTabsQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getGenericPageComponents } from './getGenericPageComponents';
import { ITabs } from '../../components/Tabs/types';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getTabs(stage: string, id: string, locale: string, market: string | null) {
  let data: any;
  try {
    data = await client.query<GetTabsQuery, GetTabsQueryVariables>({
      query: GetTabsDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        stage: stage as Stage,
        where: {
          id: id as Scalars['ID'],
        },
      },
    });
    if (data && data.data && data.data.tab && data.data.tab.tabs && data.data.tab.tabs.length > 0) {
      const tabData: ITabs = data.data.tab;
      const parsedTabs = tabData?.tabs?.map(async (tab: any) => ({
        id: tab.id,
        tabTitle: tab.tabTitle,
        components: await getGenericPageComponents(stage, tab.components, locale, market),
      }));
      // @ts-ignore
      tabData.tabs = await Promise.all(parsedTabs);
    }
    return data;
  } catch (error: any) {
    console.error('Failed to fetch generic page with error:', error);
    return {};
  }
}
