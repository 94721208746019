// TODO 2199 delete this file when CorpGridColumnComponent no longer in use in cms
import {
  GetCorpGridColumnComponentDocument,
  GetCorpGridColumnComponentQuery,
  GetCorpGridColumnComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpGridColumnComponent(stage: string, id: string, locale: string) {
  return client.query<GetCorpGridColumnComponentQuery, GetCorpGridColumnComponentQueryVariables>({
    query: GetCorpGridColumnComponentDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
