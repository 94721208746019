import {
  GetAuthorableContentCardDocument,
  GetAuthorableContentCardQuery,
  GetAuthorableContentCardQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { getLocalesForGraphQL } from '../utils/helpers';
import { client } from './client';

export async function getAuthorableContentCard(stage: string, id: string, locale: string) {
  return client.query<GetAuthorableContentCardQuery, GetAuthorableContentCardQueryVariables>({
    query: GetAuthorableContentCardDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
