import {
  GetFooterDocument,
  GetFooterQuery,
  GetFooterQueryVariables,
  Locale,
  Stage,
} from 'generated/graphql';
import { client } from 'lib/graphql/client';
import { getLocalesForGraphQL } from '../utils/helpers';


export function getFooterNavigation(stage: string, locale: string) {
  return client.query<GetFooterQuery, GetFooterQueryVariables>({
    query: GetFooterDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
    },
  });
}
