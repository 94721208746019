import {
  GetAnchorNavigationListDocument,
  GetAnchorNavigationListQuery,
  GetAnchorNavigationListQueryVariables,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';

export async function getAnchorNavigationList(stage: string, id: string) {
  return client.query<GetAnchorNavigationListQuery, GetAnchorNavigationListQueryVariables>({
    query: GetAnchorNavigationListDocument,
    variables: {
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
