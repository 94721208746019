import {
  GetBigNumberDocument,
  GetBigNumberQuery,
  GetBigNumberQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getBigNumber(stage: string, id: string, locale: string) {
  return client.query<GetBigNumberQuery, GetBigNumberQueryVariables>({
    query: GetBigNumberDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
