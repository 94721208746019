import {
  GetCorpTimelineAccordionWrapperDocument,
  GetCorpTimelineAccordionWrapperQuery,
  GetCorpTimelineAccordionWrapperQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getCorpTimelineAccordionWrapper(stage: string, id: string, locale: string) {
  return client.query<
  GetCorpTimelineAccordionWrapperQuery,
  GetCorpTimelineAccordionWrapperQueryVariables
  >({
    query: GetCorpTimelineAccordionWrapperDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
