import {
  GetCorpBanner1Document,
  GetCorpBanner1Query,
  GetCorpBanner1QueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';

export async function getCorpBanner1(stage: string, id: string, locale: string) {
  return client.query<GetCorpBanner1Query, GetCorpBanner1QueryVariables>({
    query: GetCorpBanner1Document,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
