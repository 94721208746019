import {
  GetLongTextDocument,
  GetLongTextQuery,
  GetLongTextQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';

import { getLocalesForGraphQL } from '../utils/helpers';

export async function getLongText(stage: string, id: string, locale: string) {
  return client.query<GetLongTextQuery, GetLongTextQueryVariables>({
    query: GetLongTextDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
