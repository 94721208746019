import {
    GetAudioComponentDocument,
    GetAudioComponentQuery,
    GetAudioComponentQueryVariables,
    Locale,
    Scalars,
    Stage,
  } from 'generated/graphql';
  import { client } from './client';
  import { getLocalesForGraphQL } from '../utils/helpers';
  
  export async function getAudioComponent(stage: string, id: string, locale: string) {
    return client.query<GetAudioComponentQuery, GetAudioComponentQueryVariables>({
      query: GetAudioComponentDocument,
      variables: {
        locales: getLocalesForGraphQL(locale),
        stage: stage as Stage,
        where: {
          id: id as Scalars['ID'],
        },
      },
    });
  }
  