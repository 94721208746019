import {
  GetGenericCardCarouseComponentDocument,
  GetGenericCardCarouseComponentQuery,
  GetGenericCardCarouseComponentQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getGenericCardCarouseComponent(locale: string, stage: string, id: string) {
  return client.query<
  GetGenericCardCarouseComponentQuery,
  GetGenericCardCarouseComponentQueryVariables
  >({
    query: GetGenericCardCarouseComponentDocument,
    variables: {
      stage: stage as Stage,
      locales: getLocalesForGraphQL(locale),
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
