import {
  GetFormDocument,
  GetFormQuery,
  GetFormQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getForm(stage: string, id: string, locale: string) {
  return client.query<GetFormQuery, GetFormQueryVariables>({
    query: GetFormDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
