import {
  GetImageCopyDocument,
  GetImageCopyQuery,
  GetImageCopyQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getImageCopy(stage: string, id: string, locale: string) {
  return client.query<GetImageCopyQuery, GetImageCopyQueryVariables>({
    query: GetImageCopyDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
