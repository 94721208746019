import {
  GetGenericAccordionTopRatedDocument,
  GetGenericAccordionTopRatedQuery,
  GetGenericAccordionTopRatedQueryVariables,
  Locale,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';
import { getLocalesForGraphQL } from '../utils/helpers';


export async function getGenericAccordionTopRated(stage: string, id: string, locale: string) {
  return client.query<GetGenericAccordionTopRatedQuery, GetGenericAccordionTopRatedQueryVariables>({
    query: GetGenericAccordionTopRatedDocument,
    variables: {
      locales: getLocalesForGraphQL(locale),
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
