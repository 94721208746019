import {
  GetIframeDocument,
  GetIframeQuery,
  GetIframeQueryVariables,
  Scalars,
  Stage,
} from 'generated/graphql';
import { client } from './client';

export async function getIframe(stage: string, id: string) {
  return client.query<GetIframeQuery, GetIframeQueryVariables>({
    query: GetIframeDocument,
    variables: {
      stage: stage as Stage,
      where: {
        id: id as Scalars['ID'],
      },
    },
  });
}
