import { client } from 'lib/graphql/client';
import { gql } from '@apollo/client';
import { Locale } from 'generated/graphql';

export async function getDictionary(locale: string, stage: string, title: string) {
  return client.query({
    query: gql`
        query GetDictionary {
            dictionary(where: {dictionaryTitle: "${title}"}, locales: [${locale}, ${Locale.default}], stage: ${stage}) {
                dictionaryItems(first: 1000) {
                    key
                    value
                }
            }
        }
    `,
  });
}
