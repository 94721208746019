import React, { ReactNode } from 'react';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import Theme from '../../types/Theme';

const CustomLinkStyle = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0%;
    left: 50%;
    bottom: -2px;
    border-bottom: 1px solid black;
    transition: all 0.5s;
  }
  &.white-b {
    &:after {
      border-bottom: 1px solid #fff;
    }
  }
  &:hover {
    &:after {
      width: 98%;
      left: 1%;
    }
  }
`;

const Anchor = tw.a``;

export const StyledLink = tw(CustomLinkStyle)`
  md:inline-block
  cursor-pointer
`;

// A link with a hover state, for consistency.
interface ILinkProps {
  href: string;
  children: ReactNode;
  target?: string;
  theme?: Theme;
}

export const Link = ({ href, children, target, theme = 'Corporate' }: ILinkProps): JSX.Element => (
  <StyledLink className={theme === 'Corporate' ? ' white-b' : ''}>
    <Anchor
      className={theme === 'Corporate' ? ' text-white' : ''}
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
    >
      {children}
    </Anchor>
  </StyledLink>
);
Link.defaultProps = {
  target: '_self',
};
